import React from "react";

import { Router } from "@reach/router"
import PrivateRoute from "components/PrivateRoute";

import Profile from "../templates/user/profile";

const App: React.FunctionComponent = () => {
  return (
    <Router>
      <PrivateRoute path="/user/profile" component={Profile} />
    </Router>
  );
};

export default App;
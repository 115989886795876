import React from "react"

import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import { isLoggedIn } from "utils/auth"

const DEFAUlT_LOGIN_PATHNAME = `/auth/login`

export interface IPrivateRoute extends RouteComponentProps {
  component: React.ComponentType<any>
}

const PrivateRoute: React.FunctionComponent<IPrivateRoute> = ({ component: Component, location, ...props}) => {

  if (!isLoggedIn() && location?.pathname !== DEFAUlT_LOGIN_PATHNAME) {
    // If we’re not logged in, redirect to the home page.
    navigate(DEFAUlT_LOGIN_PATHNAME);
    return null;
  }
 
  return <Component {...props} />;
}

export default PrivateRoute;